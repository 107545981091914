<template>
    <w-dialog  v-model="choiceDialog" draggable title="新增PK" width="1000px">
      <div style="display:flex;justify-content: space-around;">
        <div class="left-item">
          <el-tabs v-model="thatTitleName" stretch class="demo-tabs">
            <el-tab-pane v-if="titleList.includes('dep-use')" label="部门&老师" name="dep-use">
              <div class="parent-list">
                <el-scrollbar>
                  <div style="display: flex;width: 500px">
                    <div @click="setDept(item,index)" v-for="(item,index) in parentList" :key="index" style="margin: 0 4px;font-size: 12px;">
                      <span style="color: #2a99ff">{{item.name}} </span> <span v-if="(index+1) != parentList.length"> - </span>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
              <el-tree style="height: 350px;overflow: auto;" v-if="fatherTree.deptUserTree && fatherTree.deptUserTree.length>0"
                       :data="fatherTree.deptUserTree" v-loading="selectTreeLoading"
                       draggable @node-drag-start="handleDragstart">
                <div class="custom-tree-node" slot-scope="{ node, data }" style="width: 100%;">
                  <div v-if="data.type == 'dept'" style="display:flex;justify-content: space-between;align-content: center">
                    <div @click="selectItem(data)">
                      <div class="el-icon-folder"></div>
                      {{data.name}}
                    </div>
                    <div class="iconfont icon-map-site toSon" @click="selectCoachAndDeptTree(data)">下级</div>
                  </div>
                  <div v-else @click="selectItem(data)">
                    <div class="el-icon-user-solid"></div>
                    {{data.name}}
                  </div>
                </div>
              </el-tree>
            </el-tab-pane>
            <el-tab-pane v-if="titleList.includes('dept')" label="部门" name="dept">
              <el-input
                  v-model="fatherTree.deptName"
                  style="width: 160px"
                  size="small"
                  placeholder="请输入名称"
              />
              <el-tree style="height: 350px;overflow: auto;" v-if="fatherTree.deptTree && fatherTree.deptTree.length>0"
                       :data="fatherTree.deptTree" v-loading="selectTreeLoading"
                       draggable @node-drag-start="handleDragstart" ref="deptTree" :filter-node-method="filterNode"
                       :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
            </el-tab-pane>
            <el-tab-pane v-if="titleList.includes('user')" label="老师" name="user">
              <el-input
                  v-model="fatherTree.coachQuery.coachName"
                  @input="selectCoach"
                  style="width: 160px"
                  size="small"
                  placeholder="请输入姓名"
              />
              <el-tree style="height: 350px;overflow: auto;margin: 10px 0 0 -10px"
                       :data="fatherTree.coachTree" v-loading="selectTreeLoading"
                       draggable @node-drag-start="handleDragstart" @node-click="selectItem"
                       :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
            </el-tab-pane>
            <el-tab-pane v-if="titleList.includes('role')" label="角色" name="role">
              <el-tree style="height: 350px;overflow: auto;"
                       :data="fatherTree.roleTree" v-loading="selectTreeLoading"
                       draggable @node-drag-start="handleDragstart" @node-click="selectItem"
                       :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
            </el-tab-pane>
<!--            <el-tab-pane v-if="titleList.includes('campus')" label="校区" name="campus">-->
<!--              <el-tree style="height: 340px;overflow: auto;"-->
<!--                       :data="fatherTree.campusTree" v-loading="selectTreeLoading"-->
<!--                       draggable-->
<!--                       :allow-drop="false"-->
<!--                       @node-drag-start="handleDragstart"-->
<!--                       :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"-->
<!--              />-->
<!--            </el-tab-pane>-->
          </el-tabs>
        </div>
        <div class="right-item" v-on:mouseover="handleMouseover">
          <div style="display: flex;justify-content: space-between;margin: 0px 8px">
            <div style="font-weight: bold;font-size: 14px">以选 <span style="color: #2a99ff">{{selectList.length}}</span> 项</div>
            <div style="font-size: 12px;color: red" @click="remove()"> 清空 </div>
          </div>
          <el-tree v-if="selectTree.length!=0" style="height: 350px;overflow: auto;margin: 10px 0 0 -10px"
                   :data="selectTree" draggable :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}">
            <template #default="{ node,data }">
              <div class="tree-item">
                <div>{{ data.name }}</div>
                <div style="margin-top: 3px;color: red" class="el-icon-delete" @click="remove(node, data)"/>
              </div>
            </template>
          </el-tree>
          <el-empty v-else :image-size="100" style="margin-top: 80px" description="点击、拖拽左侧数据到此处！"/>
        </div>
      </div>
    </w-dialog>
</template>
<script>

import {selectCoach,selectDeptTree,selectRole,selectCoachAndDeptTree} from '@/api/org'
export default {
  name: "ChoiceMember",
  props: {
    //可以使用那些title 如：['user','dept']
    titleList: {
      type: Array,
      default: function () {
        return []
      }
    },
    //默认选择哪个title
    defaultTitleName:{
      default: 'dep-use',
      type: String
    },
    //是否可以多选
    multiple: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      parentList:[{name:"默认",dept:null}],
      selectTreeLoading:false,
      choiceDialog:false,
      thatTitleName:null,
      fatherTree:{
        deptUserTree:[],
        deptName:"",
        deptTree:[],
        coachQuery:{
          coachName:"",
          pageIndex: 1,
          pageSize: 20
        },
        coachTree:[],
        roleTree:[],
        campusTree:[]
      },
      selectList:[],
      selectTree:[],
      dragData:{},
      dragState:false,
    }
  },
  methods: {
    showDialog() {
      this.choiceDialog = true;
      if (this.titleList.includes("dep-use")){
        this.selectCoachAndDeptTree();
      }
      if (this.titleList.includes("user")){
        this.selectCoach();
      }
      if (this.titleList.includes("dept")){
        this.selectDeptTree();
      }
      if (this.titleList.includes("role")){
        this.selectRole();
      }
      this.thatTitleName = this.defaultTitleName;
    },
    /**
     * 开始拖拽事件
     */
    handleDragstart (node) {
      this.dragState = true;
      this.dragData = JSON.parse(JSON.stringify(node.data));
    },
    /**
     * 拖拽结束后会触发，鼠标移入事件，然后进行数据移入
     */
    handleMouseover() {
      if (this.dragState == true){
        if (this.multiple == true){
          this.selectTree.push(this.dragData);
          this.$confirm('', '提示', {
            confirmButtonText: '全部（含子级）',
            cancelButtonText: '仅当前部门',
          }).then(() => {
          }).catch(() => {
          });
        }else {
          this.dragData.children = null;
          this.$set(this.selectTree, 0, this.dragData);
        }
      }
      this.dragData = {};
      this.dragState = false;
    },
    /**
     * 树结构搜索
     */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /**
     * 部门和用户的Title 选择上级的部门，然后进行查询
     */
    setDept(item,index){
      this.selectCoachAndDeptTree(item);
      this.parentList.splice(index + 1);
    },
    /**
     * 选择事件
     */
    selectItem(item){
      if (item){
        if (this.multiple == true){
          this.selectTree.push(item);
        }else {
          this.$set(this.selectTree, 0, item);
        }
      }
    },
    /**
     * 查部门和部门下的教练
     */
    selectCoachAndDeptTree(val){
      let item = {deptId:null}
      if (val){item.deptId = val.id;this.parentList.push(val);}
      this.selectTreeLoading = true;
      selectCoachAndDeptTree(item).then(val =>{
        this.selectTreeLoading = false;
        this.fatherTree.deptUserTree = val.data;
      })
    },
    /**
     * 查教练
     */
    selectCoach(){
      this.selectTreeLoading = true;
      selectCoach(this.fatherTree.coachQuery).then(val =>{
        this.selectTreeLoading = false;
        if (val.status == 200){
          this.fatherTree.coachTree = val.data;
        }
      })
    },
    /**
     * 查部门
     */
    selectDeptTree(){
      this.selectTreeLoading = true;
      selectDeptTree().then(val => {
        this.selectTreeLoading = false;
        if (val.status == 200){
          this.fatherTree.deptTree = val.data;
        }
      })
    },
    /**
     * 查角色
     */
    selectRole(){
      this.selectTreeLoading = true;
      selectRole().then(val => {
        this.selectTreeLoading = false;
        if (val.status == 200){
          this.fatherTree.roleTree = val.data;
        }
      })
    },
    /**
     * 移除组下面的树结构某个目录
     */
    remove(node,data) {
      if (node && data){
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(d => d.id === data.id);
        children.splice(index, 1);
      }else {
        this.selectTree = []
      }

    },
  },
  created() {
  },
  watch: {
    'fatherTree.deptName'(val) {
      this.$refs.deptTree.filter(val);
    }
  },
}
</script>

<style scoped>
.left-item{
  width: 290px;
  padding: 20px;
  height: 450px;
  overflow: auto;
  border-radius: 20px;
  box-shadow: 0px 1px 18px 1px #f5f5f5;
}
.parent-list{
  height: 30px;
  background-color: #f5f5f5;
  line-height: 30px;
  padding: 0 6px;
  border-radius: 6px;
  margin-bottom: 2px;
  color: #606266;
}
.right-item{
  width: 300px;
  height: 450px;
  padding: 20px 10px;
  overflow: auto;
  border-radius: 20px;
  box-shadow: 0px 1px 18px 1px #f5f5f5;
}
::-webkit-scrollbar {
  width: 8px; /* 滚动条宽度 */
}
.toSon{
  color: #2a99ff;
  margin-right: 10px
}
::-webkit-scrollbar-thumb {
  background: #cecece; /* 滚动条颜色 */
  border-radius: 5px; /* 圆角 */
}
::-webkit-scrollbar-thumb:hover {
  background: #909090; /* 悬停时的颜色 */
}
.tree-item{
  display: flex;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  margin-right: 6px;
}

</style>